import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Timeline',
      paragraph: 'A brief history of our organization\'s history and upcoming milestones'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Spring 2023">
                Design Team founded in the Johns Hopkins Department of Biomedical Engineering
              </TimelineItem>
              <TimelineItem title="Summer 2023">
                Matched with critical mentors Dr. Adam Schiavi and Geoffrey Miller, began investigating patient harm in the Neuro-ICU
              </TimelineItem>
              <TimelineItem title="Fall 2023">
                Shadowed extensively in the Neuro-ICU, interviewed dozens of clinicians & developed a base of background research
              </TimelineItem>
              <TimelineItem title="Spring 2024">
                Built our first prototype (proof of concept) to demonstrate the viability of our solution
              </TimelineItem>
              <TimelineItem title="Summer 2024">
                Founded B9 Stimulus Technologies LLC, filed for IP & submitted application to IRB
              </TimelineItem>
              <TimelineItem title="Fall 2024">
                Begun development our minimum viable product –  a self-contained, battery-powered device equipped with safety measures and a user interface.
              </TimelineItem>
              <TimelineItem title="2025">
                We aim to initiate Clinical Trials and refine our prototype.
              </TimelineItem>
              <TimelineItem title="2026">
                We will seek approval by the FDA for our device and begin Design-for-Manufacture.
              </TimelineItem>
              <TimelineItem title="2027+">
               We hope to launch our product and expand across hospitals around the world.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;