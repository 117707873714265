import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Logo from '../layout/partials/Logo';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  state = {
    videoModalActive: false,
    demoModalActive: false,
    email: '', // new state to hold email input
    subscribeStatus: '' // new state to manage success or error messages
  }  

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }  

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false, subscribeStatus: '' });
  }

  handleInputChange = (e) => {
    this.setState({ email: e.target.value });
  }

  handleSubscribe = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const scriptURL = "https://script.google.com/macros/s/AKfycbwQCraTdXH-kqm63OF1RvEJYPfQ9SocuBwKHODl98ntxVpgufxFMSx_dNLkUAtI400K1A/exec"; // replace with your actual Google Apps Script URL

    try {
      const response = await fetch(scriptURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ 'email': email })
      });
      if (response.ok) {
        this.setState({ subscribeStatus: 'Thanks for subscribing!' });
      } else {
        this.setState({ subscribeStatus: 'Something went wrong. Please try again.' });
      }
    } catch (error) {
      console.error("Error!", error);
      this.setState({ subscribeStatus: 'Error submitting form. Please try again.' });
    }
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
               <div className="split-item-image center-content-mobile reveal-from-bottom">
                    <Image
                      src={require('./../../assets/images/logo6.png')}
                      alt="Hero"
                      width={500}
                      height={500} />
                </div>
                <div className="hero-content split-item-content center-content-mobile reveal-from-top">
                  <h1 className="mt-0 mb-16">
                    Non-harmful peripheral pain stimulation
                  </h1>
                  <p className="mt-0 mb-32">
                    Addressing patient injury caused by frequent consciousness assessments in the Neuro-ICU.
                  </p>
                  <ButtonGroup>
                      <Button
                    color="primary"
                    aria-controls="demo-modal"
                    onClick={this.openModal}>Join our Newsletter</Button>
                    <Button tag="a" color="dark" href="https://www.linkedin.com/company/b9-stimulus-technologies/" wideMobile>
                      Follow Us On LinkedIn
                    </Button>
                    <div className="center-content">
            
                   </div>
                     
                  </ButtonGroup>
                </div>
                
                <Modal
                  id="demo-modal"
                  show={this.state.demoModalActive}
                  handleClose={this.closeModal}
                >
                  <div className="center-content">
                    <Logo/>
                    <h3 className="mt-16 mb-8 text-color-low ">Join our newsletter</h3>
                    <p className="text-sm">
                    We’d love for you to join our newsletter! Stay updated on our latest progress, upcoming goals, sneak peeks, and exclusive opportunities to get involved as we grow.
                    </p>
                  </div>
                  <form onSubmit={this.handleSubscribe} style={modalFormStyle}>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="This is a label"
                        placeholder="Your best email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        labelHidden />
                    </div>
                    <Button type="submit" color="primary" wide>Subscribe</Button>
                    <p className="text-sm mt-8">{this.state.subscribeStatus}</p>
                  </form>
                  <div className="center-content mt-24">
                    <a
                      className="text-xs"
                      aria-label="close"
                      href="#0"
                      onClick={this.closeModal}
                    >No thanks!</a>
                  </div>
                </Modal>     
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

const modalFormStyle = {
  maxWidth: '400px',
  margin: '0 auto'
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;
