import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Tabs, { TabList, Tab, TabPanel } from './../elements/Tabs';
import Image from '../elements/Image';
import FeaturesSplitDamage from '../sections/FeaturesSplitDamage';
import FeaturesSplitStandard from '../sections/FeaturesSplitStandard';
import FeaturesSplitDistrust from '../sections/FeaturesSplitDistrust';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class FeaturesTabs extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tabs section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tabs-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Core Issues',
      paragraph: 'After of hundreds of hours literature review, shadowing in the Neuro-ICU, and interviews with clinicians, our team has identified the following three core issues: '
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Tabs active="tab-a">
              <TabList>
                <Tab tabId="tab-a">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require('./../../assets/images/features-tabs-icon-01.svg')}
                      alt="Tab icon 01"
                      width={50}
                      height={50} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Long Term Damage
                  </div>
                </Tab>
                <Tab tabId="tab-b">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require('./../../assets/images/features-tabs-icon-02.svg')}
                      alt="Tab icon 02"
                      width={50}
                      height={50} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Lack of Standardization
                  </div>                  
                </Tab>
                <Tab tabId="tab-c">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require('./../../assets/images/features-tabs-icon-03.svg')}
                      alt="Tab icon 03"
                      width={50}
                      height={50} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                      Distrust in Physicians
                  </div>                  
                </Tab>            
              </TabList>
              <TabPanel id="tab-a">
                <FeaturesSplitDamage invertMobile topDivider />
              </TabPanel>
              <TabPanel id="tab-b">
                <FeaturesSplitStandard invertMobile imageFill topDivider />
              </TabPanel>
              <TabPanel id="tab-c">
               <FeaturesSplitDistrust invertMobile imageFill topDivider />
              </TabPanel>            
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTabs.propTypes = propTypes;
FeaturesTabs.defaultProps = defaultProps;

export default FeaturesTabs;