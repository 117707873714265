import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import Testimonial from '../components/sections/Testimonial';
import Clients from '../components/sections/Clients';
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import News from '../components/sections/News';

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Newsletter modal is a component commonly used'
    }    

    const genericSection02Header = {
      title: 'Button is a component commonly used'
    }

    const genericSection03Header = {
      title: 'Input form is a component commonly used'
    }

    const genericSection04Header = {
      title: 'Frequently asked questions'
    }    

    return (
      <React.Fragment>
        {/* <Testimonial className="has-bg-color-cut illustration-section-02" /> */}
        <News hasBgColor invertColor/>
        <Team />


        <GenericSection hasBgColor invertColor>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="Is the device safe for long-term use?" active>
               Yes, the device only operates at pre-determined temperatures that are safe for long-term application to human skin, as shown by prior literature. 
              </AccordionItem>
              <AccordionItem title="Is the thermal grill illusion still effective in unconscious patients?">
                Yes, prior studies have shown the thermal grill illusion is just as effective when the stimulus is applied to unconscious patients.
              </AccordionItem>
              <AccordionItem title="How can I help?">
               Join our newsletter to stay updated on our progress, and get in contact if you have any thoughts – we'd love to chat!
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>         
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}

export default Secondary;