import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import emailjs from "emailjs-com";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      status: "idle" // Status for button text: "idle", "success", "error"
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, status: "idle" }); // Reset status on input change
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ status: "sending" }); // Optional: add "sending" status if needed

    emailjs
      .sendForm("service_ejnc675", "template_vtnunva", e.target, "OsI8o599fHLyBzBvo")
      .then(
        (result) => {
          console.log(result.text);
          this.setState({ name: "", email: "", message: "", status: "success" });
        },
        (error) => {
          console.log(error.text);
          this.setState({ status: "error" });
        }
      );
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Get In Touch',
      paragraph: 'Please fill out the form below to send us an email and we will get back to you as soon as possible.'
    };

    // Determine button text based on status
    const buttonText = this.state.status === "success" 
      ? "Sent" 
      : this.state.status === "error" 
      ? "Send Failed" 
      : "Send Message";

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-xs">
          <div className={innerClasses}>
            <SectionHeader tag="h2" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form onSubmit={this.handleSubmit}>
                    <fieldset>
                      <div className="mb-12">
                        <Input
                          name="name"
                          label="Name"
                          placeholder="Name"
                          labelHidden
                          value={this.state.name}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          labelHidden
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="textarea"
                          name="message"
                          label="Message"
                          placeholder="Message"
                          labelHidden
                          value={this.state.message}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="mt-24 mb-32">
                        <Button color="primary" wide type="submit">{buttonText}</Button>
                      </div>
                    </fieldset>
                  </form>
                    <div className="signin-bottom has-top-divider">
                    <div className="pt-32 text-xs center-content text-color-low">
                        <span>Email not working? Email us at </span>
                        <a href="mailto:b9st.contact@gmail.com" className="func-link">b9st.contact@gmail.com</a>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
